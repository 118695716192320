import SheSurrenders from './she_surrenders.png';
import BrightenUp from './logo.jpeg';
import ApartmentWorkouts from './icon.png';

import './SectionFive.css';

const SectionFive = () => {
    return (
        <div className='section-five' id='section-five'>
            <div className='divider'>
                <div className="custom-shape-divider-bottom-1682023199">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                    </svg>
                </div>
            </div>
            <div className='section-five-content'>
                <div className='first-section'>
                    <h2 className='first-section-header'>Trusted Among Industry Leaders</h2>
                    <p>
                        Partner with The Modern Mentors for exceptional
                        value and trusted expertise in website building and
                        business optimizations. Our team of experienced mentors delivers
                        customized, data-driven solutions that drive growth and profitability,
                        earning us a reputation as a trusted leader in the industry.
                    </p>
                </div>
                <div className='second-section'>
                    <div className='leaders'>
                        <div className='leader-container'>
                            <img src={SheSurrenders} className='leader-img' alt='she surrenders' />
                            <h3 className='leader-header'>
                                <a href='https://www.shesurrenders.com/' className='leader-link' target='_blank' rel="noreferrer">She Surrenders</a>
                            </h3>
                            <p className='leader-detail'>
                                She Surrenders is a faith-based community of people
                                that work together on their sobriety. They came to us
                                looking to switch from WordPress to Wix, while also
                                migrating all of their monthly site users over.
                            </p>
                        </div>
                        <div className='leader-container'>
                            <img src={BrightenUp} className='leader-img brighten-up' alt='brighten up detailing' />
                            <h3 className='leader-header'>
                                <a href='http://brightenupdetailing.com/' className='leader-link' target='_blank' rel="noreferrer">Brighten Up Detailing</a>
                            </h3>
                            <p className='leader-detail'>
                                Brighten Up Detailing is a mobile detailing company
                                based in Austin, TX. They came to us wanting a simple
                                landing page that their customers could use to review their
                                services and book them.
                            </p>
                        </div>
                        <div className='leader-container'>
                            <img src={ApartmentWorkouts} className='leader-img brighten-up' alt='apartment workouts' />
                            <h3 className='leader-header'>
                                <a href='https://apartmentworkouts.com/' className='leader-link' target='_blank' rel="noreferrer">Apartment Workouts</a>
                            </h3>
                            <p className='leader-detail'>
                                ApartmentWorkouts is a website that recommends workouts and
                                workout equipment best suited for apartment living. They came to us
                                to create an affiliate website that was maximized for SEO.
                            </p>
                        </div>
                    </div>
                </div>
            </div>               
        </div>
    );
}

export default SectionFive;