import { useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';
import RecessionProofAd from './recession-proof-your-business.png';

import './Ad.css';

// ad url format: http://themodernmentors.com/?name=recession-proof-your-business&source=facebook
// any queryparams allowed
// names are unique and must match an ad
const Ad = (props) => {
    const [payload, setPayload] = useState();
    const [adImage, setAdImage] = useState();
    const [emailSubmitDisabled, setEmailSubmitDisabled] = useState(false);
    const [downloadDisabled, setDownloadDisabled] = useState(false);

    const revealAd = () => {
        const downloadReady = document.getElementById('download-ready');
        const emailForm = document.getElementById('email-form');
        const img = document.getElementById('img');
        downloadReady.style.display = 'block';
        emailForm.style.display = 'none';
        img.style.filter = 'blur(0px)';
    }

    const sendEmail = (payload) => {
        emailjs.send(
            process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
            process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID_AD,
            payload,
            process.env.REACT_APP_EMAIL_JS_PUBLIC_USER_ID
        )
        .then((result) => {
            revealAd();
        }, (error) => {
            console.error(error.text);
            alert("There was a problem retrieving your infograohic. Please try again or contact The Modern Mentors");
        });
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setEmailSubmitDisabled(true);

        const tempPayload = {
            email: document.getElementById('email').value,
            queryParams: JSON.stringify(props.queryParams).replace(/\\/g,''),
            didDownload: false
        }
        setPayload(tempPayload);
        sendEmail(tempPayload);
    }

    const handleClick = () => {
        setDownloadDisabled(true);

        const tempPayload = payload;
        tempPayload.didDownload = true;
        setPayload(tempPayload);
        // todo: not sending didDownload update email
        // sendEmail(tempPayload);
    }

    const setAd = () => {
        const name = props.queryParams.name;

        // url needs a name
        if (!name) {
            window.location.href = '/';
            return;
        }

        switch(name) {
            case 'recession-proof-your-business':
                setAdImage(RecessionProofAd)
                break;
            default:
                console.error('Unkown ad name:', name)
                break;
        }
    }

    useEffect(() => {
        setAd();
    }, [])

    return (
        <div className='ad'>
                <div className="ad-header">
                    <a href="/" className="ad-link">
                        <div className="ad-header-content">
                            <div><img src="./favicon.ico" className="ad-logo" /></div>
                            <div className="ad-header-text">The Modern Mentors</div>
                        </div>
                    </a>
                </div>
                <div className="ad-content">
                    <div>
                        <div className="ad-download-content">
                            <div className="ad-email-form" id="email-form">
                                <form id="email-form" onSubmit={onSubmit}>
                                    <div className="ad-download-text">Enter your email to view the infographic</div>
                                    <input type="email" id="email" required className="ad-form-input" placeholder='Enter your email' />
                                    <button type="submit" className={`${'ad-download-btn'} ${emailSubmitDisabled ? 'ad-btn-disabled' : ''}`} disabled={emailSubmitDisabled}>Submit</button>
                                </form>
                            </div>
                            <div className="ad-download-ready" id="download-ready">
                                <div className="ad-download-text">Your infographic is ready!<br />You can view and download it below.</div>
                                <button className={`${'ad-download-btn'} ${downloadDisabled ? 'ad-btn-disabled' : ''}`} disabled={downloadDisabled}>
                                    <a onClick={handleClick} href={adImage} download className={`${'ad-download-link'} ${downloadDisabled ? 'ad-link-disabled' : ''}`}>DOWNLOAD IMAGE</a>
                                </button>
                            </div>
                        </div>
                        <div className="ad-img-container">
                            <img src={adImage} className="ad-img" id="img" />
                        </div>
                    </div>
                </div>
        </div>
    );
}

export default Ad;