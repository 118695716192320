import Header from './components/Header/Header';
import SectionOne from './components/Section-One/SectionOne';
import SectionTwo from './components/Section-Two/SectionTwo';
import SectionThree from './components/Section-Three/SectionThree';
import SectionFour from './components/Section-Four/SectionFour';
import SectionFive from './components/Section-Five/SectionFive';
import SectionSix from './components/Section-Six/SectionSix';
// import SectionSeven from './components/SectionSeven/SectionSeven';
import SectionEight from './components/SectionEight/SectionEight';
import Ad from './components/Ad/Ad';

import './App.css';
import { useEffect, useState } from 'react';

function App() {
    const [isLoadingAd, setIsLoadingAd] = useState(false);
    const [queryParams, setQueryParams] = useState({});

    const getUrlVars = () => {
        const url = window.location.href;
        var vars = {};
        const hashes = url.split("?")[1];
        
        // no query params, dip
        if(!hashes) return vars;

        var hash = hashes.split('&');

        for (var i = 0; i < hash.length; i++) {
            const params = hash[i].split("=");
            vars[params[0]] = params[1];
        }

        return vars;
    }

    useEffect(() => {
        const tempQueryParams = getUrlVars();
        setQueryParams(tempQueryParams)
        setIsLoadingAd(tempQueryParams.source && true);
    }, []);

    return (
        <div className="App">
            {isLoadingAd
                ? <Ad queryParams={queryParams} />
                : <>
                    <Header />
                    <SectionOne />
                    <SectionTwo />
                    <SectionFour />
                    <SectionThree />
                    {/* <SectionSeven /> */}
                    <SectionFive />
                    <SectionSix />
                    <SectionEight />
                </>
            }
        </div>
    );
}

export default App;
