import TestimonialOne from './testimonial-1-edited.png';
import TestimonialTwo from './testimonial-2-edited.png';
import TestimonialThree from './testimonial-3-edited.png';

import './SectionSix.css';

const SectionSix = () => {
    return (
        <div className='section-six' id='section-six'>
            <h2 className='section-six-header'>What Our Clients Say</h2>
            <div className='testimonial-container'>
                <div className='testimonial'>
                    <img src={TestimonialOne} className='testimonial-img' alt='testimonial 1' />
                </div>
                <div className='testimonial'>
                    <img src={TestimonialTwo} className='testimonial-img' alt='testimonial 2' />
                </div>
                <div className='testimonial'>
                    <img src={TestimonialThree} className='testimonial-img' alt='testimonial 3' />
                </div>
            </div>
        </div>
    );
}

export default SectionSix;