import * as React from 'react';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';

import { useState } from 'react';

import Logo from '../../logo.png'

import './Header.css';

const Header = () => {
    const [showHeader, setShowHeader] = useState(false);
    const drawerWidth = 240;

    const scrollTo = (to) => {
        // document.getElementById(to).scrollIntoView({ behavior: "smooth"});
        // offset for header height
        window.scrollTo({
            behavior: 'smooth',
            top:
                document.getElementById(to).getBoundingClientRect().top -
                document.body.getBoundingClientRect().top - 90
        });
    }

    const mobileNav = (to) => {
        scrollTo(to);
        setTimeout(() => {
            setShowHeader(false);
        }, 1000)
    }

    return (
        <div className='header'>
            <div className='app-bar'>
                <div className='mobile-nav-container'>
                    <div><MenuIcon onClick={() => setShowHeader(!showHeader)} /></div>
                </div>
                <div className='logo-container'>
                    <img src={Logo} alt='The Modern Mentors Logo' className='logo' />
                    <div className='logo-name'>The Modern Mentors</div>
                </div>
                <div className='nav-container'>
                    <Button className='nav-btn' onClick={() => scrollTo('section-one')}>Home</Button>
                    <Button className='nav-btn' onClick={() => scrollTo('section-three')}>Product</Button>
                    <Button className='nav-btn' onClick={() => scrollTo('section-five')}>Clients</Button>
                    <Button className='nav-btn' onClick={() => scrollTo('section-eight')}>Contact</Button>
                    {/* <Button variant="contained" className='action-btn' onClick={() => scrollTo('section-eight')}>Get Started</Button> */}
                </div>
            </div>
            <Drawer
                variant="temporary"
                open={showHeader}
                onClose={() => setShowHeader(false)}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth
                }}}
            >
                    <Button className='nav-btn first-nav-btn' onClick={() => mobileNav('section-one')}>Home</Button>
                    <Button className='nav-btn' onClick={() => mobileNav('section-three')}>Product</Button>
                    <Button className='nav-btn' onClick={() => mobileNav('section-five')}>Clients</Button>
                    <Button className='nav-btn' onClick={() => mobileNav('section-eight')}>Contact</Button>
            </Drawer>
        </div>
    );
}

export default Header;