import ChatBox from './chatbox.png';
import Design from './design.png';
import File from './file.png';
import Toolkit from './toolkit.png';

import './SectionFour.css';

const SectionFour = () => {
    return (
        <div className='section-four'>
            <div className='section-four-container'>
                <div className='four-first-half'>
                    <img src={ChatBox} className='chat-img' alt="chat-box" />
                </div>
                <div className='four-second-half'>
                    <p className='section-four-header'>Built for Creatives,</p>
                    <p className='section-four-header'>by Creatives</p>
                    <p className='section-four-text'>
                        As a team of creatives ourselves, we understand the importance of
                        having a website that not only looks great but also functions effectively
                        and efficiently. We strive to create websites that showcase our clients'
                        creative talents and skills, while also providing a seamless user experience
                        for their audiences. Whether it's building a portfolio, launching an online store,
                        or sharing your work with the world, with our expertise and dedication,
                        we are proud to build websites that are truly "Built for Creatives, by Creatives".
                    </p>
                    <div className='four-tag-container'>
                        <div className='tag'>
                            <img src={Toolkit} className='tag-img' alt="toolkit" />
                            <p className='tag-text'>Collaborative <br /> Process</p>
                        </div>
                        <div className='tag'>
                            <img src={File} className='tag-img' alt="file" />
                            <p className='tag-text'>Transparent <br /> Pricing</p>
                        </div>
                        <div className='tag'>
                            <img src={Design} className='tag-img' alt="design" />
                            <p className='tag-text'>Total Design <br /> Freedom</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SectionFour;