import { useState, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import emailjs from '@emailjs/browser';

import FooterImg from './footer-img.png';

import './SectionEight.css';

const SectionEight = () => {
    const [showMsg, setShowMsg] = useState(false);
    const form = useRef();

    const onSubmit = (event) => {
        const btn = document.getElementById("submit-button");
        event.preventDefault();
        // form inputs, NOT raw values
        const { name, email, subject, phone, message } = form.current;

        emailjs.sendForm(
            process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
            process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID_CONTACT,
            form.current,
            process.env.REACT_APP_EMAIL_JS_PUBLIC_USER_ID
        )
        .then((result) => {
            try {
                fetch("https://eol7xdtr5s6uxmq.m.pipedream.net", {
                    method: "POST",
                    body: JSON.stringify({
                        'name': name.value,
                        'email': email.value,
                        'subject': subject.value,
                        'phone': phone.value,
                        'message': message.value
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then((response) => {});
            } catch (error) {
                console.error('ERROR => ', error);
            }

            form.current.reset();
            btn.style.cursor = 'not-allowed';
            btn.disabled = true;
            setShowMsg(true);
        }, (error) => {
            console.log(error.text);
            alert("There was a problem sending your message. Please refresh and try again");
        });
    }

    return (
        <div>
            <div className='section-eight' id='section-eight'>
                <div className='img-container'>
                    <img src={FooterImg} className='footer-img' alt='footer' />
                </div>
                <div className='form-container'>
                    <h2 className='section-eight-header'>Contact Us</h2>
                    <p className='sub-header'>Contact us today to get started.</p>
                    <div className="contact-form">
                        <form id="contact-form" data-toggle="validator" onSubmit={onSubmit} ref={form}>
                            <div>
                                <div className='form-field'>
                                        <TextField className='text-field' type="text" name="name" placeholder="Your Name" data-error="Name is required." required />
                                </div>
                                <div>
                                    <div>
                                        <TextField className='text-field' type="email" name="email" placeholder="Your Email" data-error="Valid email is required." required />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <TextField className='text-field' type="text" name="subject" placeholder="Subject" data-error="Subject is required." required />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <TextField className='text-field' type="text" name="phone" placeholder="Phone" data-error="Phone is required." required />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <TextField className='text-field' multiline rows={4} placeholder="Your Mesaage" name="message" data-error="Please, leave us a message." required />
                                    </div>
                                </div>
                                <p className="form-message"></p>
                                <div>
                                    <div>
                                        <Button type="submit" className="submit-btn" id="submit-button">Send Message</Button>
                                    </div>
                                    <div className='message-container'>
                                        {showMsg &&
                                            <div className="message-sent">
                                                Your message has been sent! ✅ <br />We appreciate your business and will be in touch with you shortly.
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <p className="copyright">Copyright © {new Date().getFullYear()} The Modern Mentors. All rights reserved.</p>
        </div>
    );
}

export default SectionEight;