import Button from '@mui/material/Button';
import app from './app.png';
import lock from './lock.png';
import scale from './scale.png';
import chat from './chat.png';

import './SectionOne.css';

const SectionOne = () => {
    const scrollTo = (to) => {
        // document.getElementById(to).scrollIntoView({ behavior: "smooth"});
        // offset for header height
        window.scrollTo({
            behavior: 'smooth',
            top:
                document.getElementById(to).getBoundingClientRect().top -
                document.body.getBoundingClientRect().top - 90
        });
    }

    return (
        <div className='section-one' id='section-one'>
            <div className='section-container'>
                <div className='first-half'>
                    <h1 className='section-one-header'>Innovate.</h1>
                    <h1 className='section-one-header'>Design. Launch.</h1>
                    <p className='section-one-sub-header'>The Modern Mentors empower your online presence and guide your digital growth</p>
                    <Button variant="contained" className='action-btn action-btn-inverse' onClick={() => scrollTo('section-eight')}>Get Started</Button>
                    <div className='tag-container'>
                        <div className='tag'>
                            <img src={lock} className='tag-img' alt="lock" />
                            <p className='tag-text'>Custom Website <br />  Design</p>
                        </div>
                        <div className='tag'>
                            <img src={scale} className='tag-img' alt="scale" />
                            <p className='tag-text'>WordPress <br /> & Wix</p>
                        </div>
                        <div className='tag'>
                            <img src={chat} className='tag-img' alt="chat" />
                            <p className='tag-text'>E-commerce <br /> Solutions</p>
                        </div>
                    </div>
                </div>
                <div className='second-half'>
                    <img src={app} className='app-img' alt="app" />
                </div>
            </div>
        </div>
    );
}

export default SectionOne;