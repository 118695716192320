import CardOne from './card-1.png';
import CardTwo from './card-2.png';
import CardThree from './card-3.png';
import CardFour from './card-4.png';

import './SectionThree.css';

const SectionThree = () => {
    return (
        <div className='section-three' id='section-three'>
            <div className='section-header-container'>
                <h2 className='section-three-header'>What We Offer</h2>
                <p className='section-header-text'>
                    We provide customized solutions that help businesses
                    achieve sustainable success through improved online presence,
                    user experience, and optimized business processes. 
                </p> 
            </div>
            <div className='card-container'>
                <div className='card'>
                    <img src={CardOne} className='card-img' alt='first card' />
                    <h2 className='card-header'>Optimized <br />Websites</h2>
                    <p className='card-text'>
                        Build effective websites optimized for search engines,
                        user experience, and conversion rates
                    </p>
                </div>
                <div className='card'>
                    <img src={CardTwo} className='card-img' alt='second card' />
                    <h2 className='card-header'>Insights &<br /> Recommendations</h2>
                    <p className='card-text'>
                        Provide insights and recommendations on business optimizations,
                        including process improvements and revenue growth strategies
                    </p>
                </div>
                <div className='card'>
                    <img src={CardThree} className='card-img' alt='third card' />
                    <h2 className='card-header'>Comprehensive <br />Customer Support</h2>
                    <p className='card-text'>
                        Work closely with clients to understand their unique goals and challenges
                    </p>
                </div>
                <div className='card'>
                    <img src={CardFour} className='card-img' alt='fourth card' />
                    <h2 className='card-header'>Custom <br /> Solutions</h2>
                    <p className='card-text'>
                        Offer customized solutions to help businesses achieve
                        sustainable success and drive growth and profitability.
                    </p>
                </div>
            </div>       
        </div>
    );
}

export default SectionThree;